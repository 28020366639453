<template>
    <v-container tag="section">

      <HyphenDatatable 
    :headers="headers" 
    :blockText="text"
    :config="{resource:'customer', title: 'Customers', enabledActions: [ 'block', 'search']}"
    />
        
      <!-- <v-data-table
    :headers="headers"
    :items="users"
    sort-by="name"
    class="elevation-1"
    @click:row="handleClick"
      :options.sync="options"
      :server-items-length="count"
    :items-per-page="10"
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Customer</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
           
        <v-dialog
          v-model="dialog"
          max-width="800px"
        >
          
         
          
        
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card >
            <v-card-text>
              <h4 class="display-2">Are you sure you want to delete this user?</h4>
            </v-card-text>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

         <v-dialog v-model="dialogBlock" max-width="550px" height="600px">
            <v-card>
            <v-card-text>
              <h4 class="display-2">Are you sure you want to {{blockedItem.isDisabled?'Unblock': 'Block'}} this user?</h4>
            </v-card-text>
              <v-container v-if="!blockedItem.isDisabled"> 
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Remarks"
                      v-model="blockRemarks"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="blue darken-1" text @click="closeBlock"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="blockItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click.stop="editItem(item)"
         
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click.stop="deleteItem(item)"
         
        class="mr-2"
      >
        mdi-delete
      </v-icon>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click.stop="blockItem(item)"
               
              v-bind="attrs"
              v-on="on"
              :class="item.isDisabled == true ? 'red--text lighten-1' : ''"
            >
              mdi-block-helper
            </v-icon>
          </template>
          <span>{{ item.isDisabled ==true ? "Unblock" : "Block" }}</span>
        </v-tooltip>
    </template>
    <template v-slot:no-data>
        No Data
      
    </template>
  </v-data-table> -->
</v-container>
</template>


<script>
import HyphenDatatable from '../component/HyphenDatatable.vue'

export default {
    
  components:{
    HyphenDatatable
  },
     
    data: () => ({
      text: "customer",
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
      ],
      users: [],
      editedIndex: -1,
      editedItem: {
       
      },
      defaultItem: {
       
      },
        count: null,
    loading:false,
    options:{},
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },


 
    methods: {
     
      newUser(){
        this.$router.push({path:"/users/create"})
      },
      handleClick(args){
        if(this.hasRole('Users','Edit User')){
          console.log(args);
          this.$router.push({path:"/users/"+args.clientId});
        }
        
        
      },
      getUsers(){
          this.loading = true;
           this.$Customer.getCustomers(this.options.page, this.options.itemsPerPage).then(data=>{
             
        this.users = data.data.data.list;
        this.count = data.data.data.count;
        this.loading = false
      }).catch(error=>{
        console.log(error);
        this.loading = false;
      })
      },
    
    },
    mounted(){
     
    },  
}
</script>

<style scoped>

</style>